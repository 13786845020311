<template>
  <div ref="pageBox">
    <SearchBox ref="SearchBox">
      <el-form slot="form" :inline="true" label-width="80px" class="unitWidth" size="small">
        <el-form-item label="标题">
          <el-input v-model="query.title" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template slot="btns">
        <el-button size="small" @click="selrele()" type="primary">搜索</el-button>
      </template>
    </SearchBox>
    <LabelContent title="精英分享审核" ref="LabelContent"></LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        ref="multipleTable"
        :data="tableData"
        class="center"
        v-loading="loading"
        :height="tableHeight + 'px'"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="creatorName" label="发布人" show-overflow-tooltip />
        <el-table-column prop="title" label="发布标题" :sort-method="sortByDate" />
        <el-table-column prop="content" label="发布内容" show-overflow-tooltip />
        <el-table-column label="发布图片/视频" show-overflow-tooltip>
          <template slot-scope="slot">
            <el-image
              style="width: 50px; height: 50px"
              v-if="slot.row.hasOwnProperty('files')"
              :preview-src-list="[slot.row.files[0].updUrl]"
              :src="slot.row.files[0].updUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间" show-overflow-tooltip />
        <el-table-column prop="auditStatus.desc" label="审核状态" show-overflow-tooltip />
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="tableDetail(scope.row)">审核</el-button>
            <!-- <el-button type="success" @click="shelves(scope.row)">上架</el-button>
            <el-button type="warning" @click="soldout(scope.row)">下架</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Factory",
  data () {
    return {
      total: 0,
      batchDeleteArr: [],
      tableHeight: 0, //表格高度
      tableData: [],
      loading: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        timeEnd: "",
        timeStart: "",
        title: "",
      },
    }
  },
  mounted () {
    this.loading = true
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelContent
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    //查询
    selrele () {
      this.init()
    },
    //精英分享列表数据
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/articles/ds/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
      }
      this.loading = false
    },
    //分页
    async paginationChange (val) {
      let that = this
      that.query.pageNum = val
      that.query.pageSize += that.query.pageSize
      let result = await that.$http.request({
        url: "/hw/admin/articles/ds/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
      }
      this.loading = false
    },
    //上架
    async shelves (row) {
      const _this = this
      this.$confirm("确认上架吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.dialogFormVisible = true
        let result = await this.$http.request({
          url: "hw/admin/articles/business_wire/audit/" + row.artId,
          method: "POST",
          params: {
            auditStatus: "success"
          }
        })
        this.$message("上架成功！")
        this.init()
      })
    },
    //下架
    soldout (row) {
      const _this = this
      this.$confirm("确定下架吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.dialogFormVisible = true
        let result = await this.$http.request({
          url: "hw/admin/articles/business_wire/audit/" + row.artId,
          method: "POST",
          params: {
            auditStatus: "fail"
          }
        })
        this.$message("下架成功！")
        this.init()
      })
    },
    batchDelete () {
      let deleteData = this.batchDeleteArr.map((item) => {
        const { server_asset_id } = item
        return { server_asset_id }
      })
      this.deleteApi(deleteData)
    },
    deleteApi (data) {
      const _this = this
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => { })
    },
    //表格行过滤
    formatterRow (row, column) { },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },
    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/share_detail/" + row.artId,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
  margin-top: 2.5vw;
  width: 17vw;
}
.el-button--success {
  width: 3.5vw;
  height: 2.5vw;
  line-height: 0px;
}
.el-button--warning {
  width: 3.5vw;
  height: 2.5vw;
  line-height: 0px;
}
.stywid1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 2.5vw;
}

.unitWidth.el-form .el-form-item .el-form-item__content .el-select,
.unitWidth.el-form .el-form-item .el-form-item__content .el-date-editor,
.unitWidth.el-form .el-form-item .el-form-item__content .el-input,
.unitWidth.el-form .el-form-item .el-form-item__content .el-input-number {
  width: 25vw;
}
</style>
